@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html,
  body {
    @apply font-sans font-bold leading-[28px] tracking-[0.02px] text-black antialiased;
  }
}

@layer components {
  .c-button {
    @apply button-1000 flex items-center justify-center gap-2.5 rounded-xl border  py-2 text-center transition-colors duration-300 disabled:border-neutral-500 disabled:bg-neutral-500 disabled:hover:border-neutral-500 disabled:hover:bg-neutral-500;
  }

  .c-button--primary {
    @apply border-2 border-primary-500 bg-primary-500 px-9 text-white 
    hover:bg-white hover:text-primary-500
    disabled:border-neutral-500 disabled:bg-neutral-500 disabled:hover:border-neutral-500 disabled:hover:bg-neutral-500 disabled:hover:text-white;
  }

  .c-button--primary-outline {
    @apply border-2 border-primary-500 bg-white px-9 text-primary-500
    hover:bg-primary-500 hover:text-white
    disabled:border-neutral-500 disabled:bg-neutral-500 disabled:hover:border-neutral-500 disabled:hover:bg-neutral-500 disabled:hover:text-white;
  }

  .c-button--secondary {
    @apply border-2 border-primary-500 bg-white px-9 text-primary-500 hover:border-primary-400 hover:bg-primary-400 disabled:border-neutral-450 disabled:bg-neutral-450 disabled:hover:border-neutral-450 disabled:hover:bg-neutral-450;
  }

  .c-sn-button {
    @apply inline-block border-primary-500 px-4 py-1.5 font-bold text-primary-500 transition-all duration-300;
  }

  .c-sn-button--primary {
    @apply border-2 hover:bg-primary-500 hover:text-white focus:border-white focus:outline focus:outline-2 focus:outline-primary-500;
  }

  .c-sn-button--reply-action {
    @apply rounded-6xl border bg-white p-4 hover:bg-primary-500 hover:text-white focus:border-white focus:outline focus:outline-2 focus:outline-primary-500;
  }

  .c-sn-button--email-action {
    @apply border border-white bg-neutral-400 text-neutral-500 transition-colors hover:border-primary-500;
  }

  .c-card {
    @apply border-2 border-[rgba(155,155,155,0.25)] bg-white shadow-light-shadow;
  }

  .c-home-section {
    background: linear-gradient(144.96deg, #076eb2 0%, #25345f 165.69%);
  }

  .c-implementer-nav {
    background: linear-gradient(270deg, #076eb2 0%, #25345f 165.69%);
  }

  .c-results-results-top-border {
    background: linear-gradient(179deg, #076eb2 0%, #25345f 165.69%);
  }

  .c-admin-dashboard-header::after {
    content: "";
    z-index: -1;
    @apply absolute inset-0 bg-[#076EB2E5];
  }

  .c-email-body a {
    @apply text-primary-500 underline;
  }
}

@layer utilities {
  /*Custom Header Styles*/
  .header-1000 {
    @apply text-4xl font-bold leading-[48px] tracking-[0.12px];
  }

  .header-900 {
    @apply text-3xl font-bold leading-9 tracking-[0.12px];
  }

  .header-800 {
    @apply text-2xl font-bold;
  }

  .header-700 {
    @apply text-xl font-bold;
  }

  .header-600 {
    @apply text-lg font-bold;
  }

  .header-500 {
    @apply text-xl font-bold leading-[20px];
  }

  .header-400 {
    @apply text-lg font-light leading-6;
  }

  .header-300 {
    @apply text-lg font-bold leading-6;
  }

  /*Custom Body Styles*/
  .body-1000 {
    @apply text-base font-bold;
  }

  .body-900 {
    @apply font-medium;
  }

  .body-800 {
    @apply font-normal;
  }

  .body-750 {
    @apply text-[40px] font-light;
  }

  .body-700 {
    @apply text-sm font-bold leading-[14px] tracking-[0.24px];
  }

  .body-600 {
    @apply text-sm font-light leading-[22px] tracking-[0.12px];
  }

  .body-650 {
    @apply text-sm font-bold leading-[22px] tracking-[0.12px];
  }

  .body-500 {
    @apply text-sm font-light tracking-[0.12px];
  }

  .body-400 {
    @apply text-sm font-light tracking-[0.12px] underline;
  }

  .body-100 {
    @apply text-xs font-light tracking-[0.16px];
  }

  /*Gradients*/
  .bg-gradient-700 {
    background: linear-gradient(144.96deg, #076eb2 0%, #25345f 165.69%);
  }

  .bg-gradient-600 {
    background: linear-gradient(156.8deg, #f7fcfe 16.92%, #d0eefa 58.5%);
  }

  .bg-gradient-500 {
    background: linear-gradient(142.24deg, #f4fafa 14.28%, #cae5e4 152.72%);
  }

  .bg-gradient-400 {
    background: linear-gradient(144.93deg, #e7f0f5 0%, #f4f7ff 168.44%);
  }

  /* Button */
  .button-1000 {
    @apply text-base font-bold leading-5;
  }

  /* Scroll Bars */
  .scroll-bar-primary-500::-webkit-scrollbar {
    @apply mr-1 w-3 bg-primary-500;
  }

  .scroll-bar-primary-500::-webkit-scrollbar-thumb {
    @apply rounded-full bg-white;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
}

.reactour__popover {
  @apply hidden;
}
